import { HeadProps, Link } from "gatsby";
import React from "react";
import BasicPage from "../components/BasicPage";
import Seo from "../features/seo/Seo";

const TermsService = () => {
  return (
    <BasicPage title="Terms of Service">
      <div className="prose prose-neutral prose-ol:list-none max-w-none">
        <small>Last updated: 11 August 2014</small>

        <p>
          The following terms and conditions (including any documents referred
          to in them) (collectively, the "Terms of Service"), apply to your use
          of the Giancolianswers.com website and all *.giancolianswers.com
          subsites (collectively, "Giancoli Answers"), as well as any content,
          functionality and services offered to you, whether as a guest or a
          registered user, on or through Giancoli Answers.
        </p>
        <p>
          Please read the Terms of Service carefully before you start to use
          Giancoli Answers. By using Giancoli Answers, you accept and agree to
          be bound and abide by these Terms of Service and our Privacy Policy,
          which can be found at{" "}
          <Link to="/privacy" title="Giancoli Answers privacy policy">
            https://www.giancolianswers.com/privacy
          </Link>{" "}
          and is incorporated here by this reference.
        </p>
        <p>
          Giancoli Answers is owned by Shaun Dychko, and operated by Shaun
          Dychko and associates ("we" or "us" or "The Company"). We may revise
          and update these Terms of Service from time to time in our sole
          discretion. If we make any material changes to these Terms of Service,
          we will notify you by posting a notice on Giancoli Answers before the
          changes are effective. Your continued use of Giancoli Answers
          following the posting of revised Terms of Service means that you
          accept and agree to the changes.
        </p>
        <p>
          IF YOU DO NOT AGREE TO THESE TERMS OF SERVICE OR TO THE PRIVACY
          POLICY, YOU MUST EXIT THE WEBSITE.
        </p>
        <h2>Accounts</h2>
        <ol>
          <li>
            By using Giancoli Answers, you represent and warrant that you are 13
            years old or older. Children under 13 can create an account only
            with prior verifiable parental consent.
          </li>
          <li>
            To access Giancoli Answers or some of the resources it offers, you
            will need to create an account and provide your email address and a
            password. You may also customize your automatically generated
            username. Some resources require you to enter your credit card
            information which will be sent directly to our payment processor{" "}
            <a
              href="https://stripe.com"
              title="Giancoli Answers payment processor"
            >
              Stripe
            </a>
            . Giancoli Answers will store a token provided by Stripe which
            represents your credit card information.
          </li>
          <li>
            You are responsible for maintaining the security of your account and
            password, and you are fully responsible for all activities that
            occur under the account. You must treat your username and password
            as confidential, and you must not disclose it to any third party.
          </li>
          <li>
            You agree to immediately notify us of any unauthorized use of your
            username or password or any other breach of security. You also agree
            to ensure that you exit from your account (typically by clicking the
            "Log Out" link, but might also include clearing the web browser
            history and cookies) at the end of each session from a public or
            shared computer.
          </li>
          <li>
            We have the right to disable any username or password at any time in
            our sole discretion for any or no reason, including, if in our
            opinion, you have failed to comply with any provision of these Terms
            of Service.
          </li>
          <li>
            User accounts are intended for personal use by an individual, unless
            the account was created for the express purpose of providing access
            to multiple students at an educational institution.
          </li>
          <li>
            Accounts created by automated methods (e.g. bots) will be blocked
            when discovered.
          </li>
        </ol>
        <h2>Content</h2>
        <ol>
          <li>
            Giancoli Answers, its features and functionality are owned by Shaun
            Dychko.
          </li>
          <li>
            Giancoli Answers and Shaun Dychko are not associated with any other
            publisher, including the textbook publisher.
          </li>
          <li>
            Book cover images, titles, and author names appear for reference
            purposes only and remain the property of their respective owners.
          </li>
          <li>
            Content, including videos, may not be distributed by any means, nor
            downloaded to a user's device for the purpose of offline use or
            storage.
          </li>
          <li>
            Content, including user-generated content like comments,
            discussions, and testimonials, on Giancoli Answers become the
            property of Shaun Dychko. It is the user's responsibility to obtain
            appropriate licensing and attribution for content they post to
            Giancoli Answers. Content without appropriate licensing or
            attribution will be removed.
          </li>
          <li>
            You may use Giancoli Answers only for lawful purposes and in
            accordance with these Terms of Service.
          </li>
        </ol>
        <p>You agree that:</p>
        <ul>
          <li>You are responsible for what you publish.</li>
          <li>
            The content you publish will not contain any material which is
            defamatory, obscene, indecent, abusive, offending, harassing,
            violent, hateful, inflammatory or otherwise objectionable.
          </li>
          <li>
            The content you publish will not promote sexually explicit or
            pornographic material, violence, or discrimination based on race,
            sex, religion, nationality, disability, sexual orientation or age.
          </li>
          <li>
            The content you publish will not knowingly infringe any patent,
            trademark, trade secret, copyright or other intellectual property
            rights of any other person.
          </li>
          <li>
            The content you publish will not violate the legal rights (including
            the rights of publicity and privacy) of others or contain any
            material that could give rise to any civil or criminal liability
            under applicable laws or regulations or that otherwise may be in
            conflict with these Terms of Service and our Privacy Policy.
          </li>
          <li>
            You will not use Giancoli Answers to transmit, or procure the
            sending of, any advertising or promotional material without our
            prior written consent, including and "spam", "junk mail", "chain
            letter" or any other similar solicitation.
          </li>
          <li>
            You will not impersonate or attempt to impersonate Shaun Dychko and
            any associates, another user, or person or entity (including,
            without limitation, the use of email addresses or screen names
            associated with any of the foregoing).
          </li>
          <li>
            The languate of Giancoli Answers is English. Content you publish
            will be written in Engligh.
          </li>
        </ul>
        <p>Additionally, you agree not to:</p>
        <ul>
          <li>
            Use the Website in any way that violates any applicable law or
            regulation (including, without limitation, any laws regarding the
            export of data or software to and from the US, Canada or other
            countries)
          </li>
          <li>
            Use the Website in any manner that could disable, overburden,
            damage, or impair the Website or interfere with any other party's
            use of the Website, including their ability to engage in real time
            activities through the Website.
          </li>
          <li>
            Use any device, software or routine that interferes with the proper
            working of the Website
          </li>
          <li>
            Introduce any viruses, Trojan horses, worms, logic bombs or other
            material which is malicious or technologically harmful
          </li>
          <li>
            Attempt to gain unauthorized access to, interfere with, damage or
            disrupt any parts of the Website, the server on which the Website is
            stored, or any server, computer or database connected to the
            Website; or
          </li>
          <li>
            Otherwise attempt to interfere with the proper working of the
            Website.
          </li>
        </ul>
        <h2>Monitoring and Enforcement</h2>
        <ol>
          <li>
            Accounts (user or educational institution) which do not follow the
            guidelines listed above will be blocked. Content which does not
            follow the guidelines listed above will be removed.
          </li>
          <li>
            We have the right to take appropriate legal action, including
            without limitation, referral to law enforcement, for any illegal or
            unauthorized use of the Giancoli Answers or terminate your access to
            all or part of the Giancoli Answers for any or no reason, including
            without limitation, any violation of these Terms of Service. Without
            limiting the foregoing, we have the right to fully cooperate with
            any law enforcement authorities or court order requesting or
            directing us to disclose the identity of anyone posting any
            materials on or through the Giancoli Answers.
          </li>
          <li>
            You waive and hold harmless the Shaun Dychko and associates from any
            claims resulting from any action taken by the Shaun Dychko and
            associates during or as a result of investigations by either the
            Shaun Dychko and associates or law enforcement authorities.
          </li>
          <li>
            We cannot and do not undertake to review all materials before they
            are posted on Giancoli Answers, and cannot ensure immediate removal
            of any objectionable material after it has been posted. Accordingly,
            we assume no liability for any action or inaction regarding
            transmissions, communications or content provided for Giancoli
            Answers by any user or third party.
          </li>
          <li>
            We do not warrant the accuracy, completeness or usefulness of any
            content posted on Giancoli Answers. We disclaim all liability and
            responsibility arising from any reliance placed on such materials by
            you or any other visitor to Giancoli Answers, or by anyone who may
            be informed of any of its contents.
          </li>
        </ol>
        <h2>Privacy</h2>
        <ol>
          <li>
            All information we collect on Giancoli Answers is subject to our
            Privacy Policy{" "}
            <Link to="/privacy" title="Privacy policy">
              https://www.giancolianswers.com/privacy
            </Link>
            . By using Giancoli Answers, you consent to all actions taken by use
            with respect to your information in compliance with the Privacy
            Policy.
          </li>
        </ol>
        <h2>Cancellation</h2>
        <ol>
          <li>
            You can delete your account at any time. Upon deletion all private
            and personally identifying information from your profile will be
            deleted. The data will stay in Giancoli Answers backups
            indefinitely.
          </li>
          <li>
            Public content you created, such as comments or schools you
            associated with your account, won't be deleted. All this content
            will attributed to an "Aonymous" user.
          </li>
          <li>Once deleted, your account is gone and can not be restored.</li>
        </ol>
        <h2>General conditions</h2>
        <ol>
          <li>
            You may link to Giancoli Answers, provided you do so in a way that
            is fair and legal and does not suggest any form of association,
            approval or endorsement on our part where none exists. Giancoli
            Answers must not be framed on any other site. You agree to cooperate
            with us in causing any unauthorized framing or linking immediately
            to cease. We reserve the right to withdraw linking permission
            without notice.
          </li>
          <li>
            Links from Giancoli Answers to other sites and resources provided by
            third parties are provided for your convenience only. This includes
            links contained in advertisements, including banner advertisements
            and sponsored links. We have no control over the contents of those
            sites or resources, and accept no responsibility for them or for any
            loss or damage that may arise from your use of them. If you decide
            to access any of the third party websites linked to Giancoli
            Answers, you do so entirely at your own risk and subject to the
            terms and conditions of use for such websites.
          </li>
          <li>
            You understand that we cannot and do not guarantee or warrant that
            files available for downloading from the internet or Giancoli
            Answers will be free of viruses or other destructive code. You are
            responsible for implementing sufficient procedures and checkpoints
            to satisfy your particular requirements for anti-virus protection
            and accuracy of data input and output, and for maintaining a means
            external to our site for any reconstruction of any lost data.
          </li>
          WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED
          DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL
          MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS,
          DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF Giancoli Answers
          OR ANY SERVICES OR ITEMS OBTAINED THROUGH Giancoli Answers OR TO YOUR
          DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO
          IT. YOUR USE OF THIS WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS
          OBTAINED THROUGH Giancoli Answers IS AT YOUR OWN RISK. Giancoli
          Answers, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH
          Giancoli Answers ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS,
          WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER
          THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY
          WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
          RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF Giancoli Answers.
          WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
          ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT Giancoli
          Answers, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH
          Giancoli Answers WILL BE ACCURATE, RELIABLE, ERROR-FREE OR
          UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE
          SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
          COMPONENTS OR THAT Giancoli Answers OR ANY SERVICES OR ITEMS OBTAINED
          THROUGH Giancoli Answers WILL OTHERWISE MEET YOUR NEEDS OR
          EXPECTATIONS. THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND,
          EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED
          TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR
          PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH
          CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          <li>
            Limitation on Liability. IN NO EVENT WILL THE COMPANY, ITS
            AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS,
            OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY
            LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR
            INABILITY TO USE, Giancoli Answers, ANY WEBSITES LINKED TO IT, ANY
            CONTENT ON Giancoli Answers OR SUCH OTHER WEBSITES OR ANY SERVICES
            OR ITEMS OBTAINED THROUGH Giancoli Answers OR SUCH OTHER WEBSITES,
            INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL
            OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY,
            PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF
            PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS
            OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
            NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
            THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED
            OR LIMITED UNDER APPLICABLE LAW.
          </li>
          <li>
            Arbitration. All disputes (whether in contract, tort or otherwise,
            whether statutory, common law or equitable and whether pre-existing,
            present or future) against Giancoli Answers or Shaun Dychko or
            associates, its agents, employees, affiliates, successors and
            assigns, relating to these Terms of Service or any aspect of
            Giancoli Answers will be resolved by binding arbitration
            administered by an arbitration service provider chosen by Shaun
            Dychko under its arbitration rules then in effect. That arbitration
            will be conducted before a single neutral arbitrator and will be
            limited solely to the dispute between Shaun Dychko, associates, and
            you, the user. The arbitration will be conducted in Vancouver, BC,
            Canada, or by telephone or online. Any award of the arbitrator will
            be final and binding on each of the parties and may be entered as a
            judgment in any court of competent jurisdiction. Either you or Shaun
            Dychko may, without violating this provision, seek from a court any
            equitable relief that may be necessary to protect the rights or
            property of that person pending the appointment of an arbitrator (or
            pending the arbitrator's determination of the merits of the
            controversy), and in those cases, you irrevocably submit to the
            jurisdiction of any court sitting in the Province of British
            Columbia, Canada, you waive any objection to jurisdiction and venue
            in those courts and any claim that forum is inconvenient, and you
            agree not to initiate any proceeding against Shaun Dychko or
            associates in any other jurisdiction.
          </li>
          <li>
            ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING
            TO THESE TERMS OF SERVICE OR THE WEBSITE MUST BE COMMENCED WITHIN
            ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH
            CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.
          </li>
          <li>
            Waiver and Severability. No waiver of these Terms of Service by the
            Company shall be deemed a further or continuing waiver of such term
            or condition or any other term or condition, and any failure of the
            Company to assert a right or provision under these Terms of Service
            shall not constitute a waiver of such right or provision. If any
            provision of these Terms of Service is held by a court of competent
            jurisdiction or the arbitrator to be invalid, illegal or
            unenforceable for any reason, such provision shall be eliminated or
            limited to the minimum extent such that the remaining provisions of
            the Terms of Service will continue in full force and effect.
          </li>
          <li>
            The Terms of Service and our Privacy Policy constitute the sole and
            entire agreement between you and the Company with respect to the
            Website and supersede all prior and contemporaneous understandings,
            agreements, representations and warranties, both written and oral,
            with respect to the Website.
          </li>
        </ol>
      </div>
    </BasicPage>
  );
};

export default TermsService;

export const Head = (props: HeadProps) => {
  const title = "Terms of service | Giancoli Answers";

  return <Seo title={title} pathname={props.location.pathname} />;
};
